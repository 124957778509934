<template>
    <b-row>
        <b-col lg="2" md="3" cols="4" class="text-right">
            <b-img-lazy :src="thumbnailSrc(user.utilisateur, 'utilisateurs')"
                        :alt="textUsername(user.utilisateur)" class="thumbnail-object-image"/>
        </b-col>
        <b-col cols="8">
            <p> Votre commentaire </p>
            <div class="pl-0">
                <balloon-editor v-model="commentaire"/>
            </div>
            <b-btn variant="success" class="mt-3" @click="newComment">Envoyer</b-btn>
        </b-col>
    </b-row>
</template>

<script>
    import {mapState} from 'vuex';

    import {textUsername} from '@/util/text';
    import {thumbnailSrc} from '@/util/image';
    import {apiPath}      from '@/util/http';
    import alert          from '@/util/alert';

    const BalloonEditor = () => import('@/components/BalloonEditor');
    const UserName      = () => import('@/components/UserName');

    export default {
        name: "EvenementCommentaireEditor",
        components: {BalloonEditor, UserName},
        props: {
            evenement: {
                type: Object,
                required: true
            }
        },
        data: () => ({
            commentaire: null
        }),
        computed: {
            ...mapState({user: 'user'})
        },
        methods: {
            textUsername,
            thumbnailSrc,
            newComment() {
                if (!this.commentaire || !this.commentaire.trim().length) {
                    this.$toaster.error("Impossible d'enregistrer un commentaire vide");
                    return;
                }

                alert.loading();
                this.axios
                    .post(
                        apiPath('new_commentaire_evenement', {evenement: this.evenement.id}),
                        {commentaire: this.commentaire}
                    )
                    .then(() => {
                        this.commentaire = null;
                        this.$toaster.success('Commentaire publié');
                        this.$emit('publication');
                    })
                    .catch(() => this.$toaster.error('Impossible de publier le commentaire'))
                    .finally(alert.stopLoading);
            }
        }
    }
</script>
